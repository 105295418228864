import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';

import WideCard from '../components/ui/wideCard';
import WideGrid from '../components/ui/wideGrid';
import PageHeader from '../components/ui/pageHeader';

const CategoriesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          translations {
            categories
          }
        }
      }  

      allStrapiKategoria {
        edges {
          node {
            strapiId
            nev
            leiras
            kep {
              id
              url
              formats
            }
          }
        }
      }
    }  
  `);

  const {
    categories: categoriesTitle,
  } = data.site.siteMetadata.translations;

  const categoriesFromCms = data.allStrapiKategoria.edges.map(node => node.node);
  const categories = categoriesFromCms.map(cat => ({
    ...cat,
    link: `/sub-categories/${cat.strapiId}`,
  }));

  return (
    <Layout noMainMarginsMobile>
      <SEO title={categoriesTitle} />

      <PageHeader noBackButton>
        {categoriesTitle}
      </PageHeader>

      <WideGrid>
        {categories.map(cat => (
          <WideCard
            key={cat.nev}
            title={cat.nev}
            subTitle={cat.leiras}
            imageUrl={cat.kep?.formats?.large?.url || cat.kep?.url}
            link={cat.link}
          >
            {cat.name}
          </WideCard>
        ))}
      </WideGrid>
    </Layout>
  );
};

export default CategoriesPage;
